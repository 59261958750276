<template>
  <div id="app" class="rr-events layout">
    <template v-if="themeLoaded">
      <TenantHeader v-if="!hideHeader"></TenantHeader>
      <router-view/>
      <TenantFooter v-if="!hideFooter"></TenantFooter>
      <alerts></alerts>
      <rr-notifications></rr-notifications>
      <CookieGDPR></CookieGDPR>
    </template>
  </div>
</template>

<script>
import Alerts from '@/components/Alerts.vue';
import RrNotifications from '@/components/Notifications.vue';
import { mapActions, mapGetters } from 'vuex';
// import { showSportmasterNotification } from '@/assets/js/sportmasterService';
import { showSbpNotification } from '@/assets/js/sbpService';
import { showExpoNotification } from '@/assets/js/expoService';
import TenantHeader from '@/components/TenantHeader.vue';
import TenantFooter from '@/components/TenantFooter.vue';
import CookieGDPR from '@/components/CookieGDPR.vue';

export default {
  components: {
    Alerts,
    RrNotifications,
    TenantHeader,
    TenantFooter,
    CookieGDPR,
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeLoaded']),
    searchParams() {
      return new URL(window.location).searchParams;
    },
    hideHeader() {
      const conditions = [
        this.searchParams.get('fs') === '1',
      ];

      return conditions.some(Boolean);
    },
    hideFooter() {
      const conditions = [
        this.searchParams.get('fs') === '1',
      ];

      return conditions.some(Boolean);
    },
  },
  methods: {
    ...mapActions('settings', ['setupTheme']),
    ...mapActions('events', ['getFilters']),
    detectTouch() {
      let isTouch = false;
      if ('ontouchstart' in document.documentElement) {
        isTouch = true;
      }
      this.$root.isTouch = isTouch;
      this.$root.iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      this.$root.android = /android/i.test(navigator.userAgent);
      document.body.className += isTouch ? ' touch' : ' no-touch';
    },
    toggleHeaderPosition() {
      const header = document.querySelector('.rr-header');
      if (header) {
        const isStatic = window.location.href.indexOf('/event/') !== -1;
        header.style.position = isStatic ? 'absolute' : '';
      }
    },
  },
  mounted() {
    this.detectTouch();
    // setTimeout(async () => {
    //   const routeName = this.$route.name;
    //   const isHomePage = (routeName === 'Home' || routeName === 'Event');
    //   const isThisRREventHomePage = this.theme === 'rr' && isHomePage;

    //   if (isThisRREventHomePage) {
    //     showSportmasterNotification({
    //       root: this.$root,
    //       props: {
    //         title: 'Бонусы «Спортмастер» за&nbsp;регистрацию на&nbsp;событие',
    //         text: 'Подключите клубную карту и получайте бонусы за&nbsp;регистрацию на&nbsp;любые события.',
    //         confirmText: 'Подключить',
    //         img: '/Content/images/sportmaster/SportMaster.svg',
    //       },
    //     });
    //   }
    // }, 1000);
    setTimeout(async () => {
      const routeName = this.$route.name;
      const isHomePage = (routeName === 'Home' || routeName === 'Event');
      const isThisRREventHomePage = this.theme === 'rr' && isHomePage;

      if (isThisRREventHomePage) {
        showExpoNotification({
          root: this.$root,
          props: {
            title: 'Бонусная программа Russia Running',
            text: 'Получите <b>200 баллов</b> за&nbsp;регистрацию&nbsp;|<br />Реклама russiarunning.com',
            confirmText: 'Присоединиться',
            img: '/Content/images/Coin.svg',
          },
        });

        showSbpNotification({
          root: this.$root,
          props: {
            title: 'Получайте скидку 5%',
            text: 'При оплате через СБП | Реклама АО «НСПК»',
            confirmText: 'Узнать больше',
            img: '/Content/images/sbp/sbp-logo.svg',
          },
        });
      }
    }, 1000);
  },
  created() {
    this.$t = this.$i18n.t;
    this.$root.$modals = {};
    this.$root.$env = process.env;
    this.getFilters();
  },
  beforeCreate() {
    window.setThemeSettings = () => this.setupTheme();
    this.$root.setMetaInfo = ({ title }) => {
      if (!window.RR_APP_SETTINGS?.name || !title) {
        return {};
      }
      const titleTemplate = `%s - ${window.RR_APP_SETTINGS.name}`;
      return {
        title,
        titleTemplate,
        meta: [
          {
            property: 'og:title',
            content: title,
            vmid: 'og:title',
            template: titleTemplate,
          },
          {
            name: 'title',
            content: title,
            vmid: 'title',
            template: titleTemplate,
          },
          {
            name: 'mrc__share_title',
            content: title,
            vmid: 'mrc__share_title',
            template: titleTemplate,
          },
        ],
      };
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.toggleHeaderPosition();
        if (window.onEventRouteChange) {
          window.onEventRouteChange(val.name);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "https://unpkg.com/leaflet@1.6.0/dist/leaflet.css";

.body-content>.container {
  padding: 0;
  max-width: 100%;
}

.empty-sub-header {
  height: 0;
}

.mobile-mode .wrapper {
  padding: 0;
}
</style>
