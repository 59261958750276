<template>
  <div class="rr-notifications">
    <transition
      name="notifications"
      tag="div"
      mode="out-in"
    >
      <rr-notification
        v-if="activeItem"
        :key="activeItem.id"
        v-bind="activeItem.props"
        @confirm="activeItem.confirm && activeItem.confirm($event)"
        @decline="activeItem.decline && activeItem.decline($event)"
        @dismiss="activeItem.dismiss && activeItem.dismiss($event)"
        @closed="remove(activeItem.id)"
      >
      </rr-notification>
    </transition>
  </div>
</template>

<script>
import RrNotification from './Notification.vue';

export default {
  name: 'RrNotifications',
  components: {
    RrNotification,
  },
  data() {
    return {
      id: 0,
      items: [],
    };
  },
  computed: {
    activeItem() {
      return this.items[0];
    },
  },
  methods: {
    addNotification(data) {
      const notification = data;
      this.id += 1;
      notification.id = this.id;
      this.items.push(notification);
    },
    remove(id) {
      this.items = this.items.filter((o) => o.id !== id);
    },
  },
  created() {
    this.$root.$addNotification = this.addNotification;
  },
};
</script>

<style lang="scss" scoped>
.rr-notifications {
  position: fixed;
  z-index: 1000;
  top: 56px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1244px;
  height: 0;
  margin: 0 auto;
  padding: 8px;
  text-align: right;
  pointer-events: none;

  @media (min-width: breakpoint(md)) {
    top: 64px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.rr-notification {
  margin-left: auto;
  margin-bottom: 16px;
  transition: all 0.2s;
  transition-delay: 1s;
}

.notifications-enter,
.notifications-leave-to {
  opacity: 0;
  transform: translateY(-16px);
}

.notifications-leave-active {
  transition-delay: 0s;
  z-index: -1;
}
</style>
