const localStorageKey = 'rr-expo-notify-hide';

function setNotificationHideInLocalStorage() {
  localStorage.setItem(localStorageKey, 1);
}

function isNotificationHiddenInLocalStorage() {
  return !!localStorage.getItem(localStorageKey);
}

async function getShowFromApi() {
  const response = await fetch('/DiscountBanners/GetSettings').catch((error) => console.error(error));

  if (!response.ok) return;

  return response.json().then((data) => data).catch((error) => console.error(error));
}

async function showExpoNotification({ props, root }) {
  if (isNotificationHiddenInLocalStorage()) return;

  const showNotification = await getShowFromApi();

  if (!showNotification.Settings) return;

  const isVisible = showNotification.Settings.find((setting) => setting.Name === 'ExpoBanner')?.IsVisible;

  if (!isVisible) return;

  root.$addNotification({
    props,
    confirm() {
      setNotificationHideInLocalStorage();
      window.open('https://rrexpo.scount.pro/?utm_source=rr_site&utm_medium=popup_events&erid=LjN8K1xgN', '_blank');
    },
    decline() {
      setNotificationHideInLocalStorage();
    },
  });
}

export {
  // eslint-disable-next-line import/prefer-default-export
  showExpoNotification,
  isNotificationHiddenInLocalStorage,
};
