<template>
  <div class="cookie-gdpr" v-if="isActive">
    <div class="cookie-gdpr__window">
      <div class="cookie-gdpr__text">
       {{ $t('cookie.text') }}
      </div>
      <div class="cookie-gdpr__actions">
        <div class="cookie-gdpr__actions-row">
          <div class="cookie-gdpr__action">
            <slot name="actions">
              <Button
                variant="primary"
                :text="$t('cookie.button')"
                mobile
                wide
                @click="hide"
              ></Button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import Button from '@rr-component-library/button/src/main';

export default {
  name: 'CookieGDPR',
  components: {
    Button,
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    hide() {
      let baseUrl = this.$env.RussiaRunning;
      const devHost = 'rr-dev.ru';
      const devUrl = `https://${devHost}`;
      const isDev = ['localhost', devHost].includes(window.location.hostname);

      if (isDev) {
        baseUrl = devUrl;
      }

      const domain = new URL(baseUrl).host;
      Cookies.set('acceptedrrcookies', 'true', { expires: 60, domain });
      this.isActive = false;
    },
  },
  mounted() {
    this.isActive = Cookies.get('acceptedrrcookies') !== 'true';
  },
};
</script>

<style lang="scss" scoped>
.cookie-gdpr {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px;
  font-family: $font-family-base;

  @media (min-width: breakpoint(md)) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__window {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    box-shadow: $elevation-deth-64;
    color: $color-black;
    border-radius: 4px;
    padding: 24px;
    max-width: 1280px;
    margin: 0 auto;

    @media (min-width: breakpoint(lg)) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__text {
    @include text-sm;
    flex: 1 1 100%;
    min-width: 0;

    a {
      color: inherit;
      text-decoration: none;
      outline: none;
      font-weight: $font-weight-semibold;
    }
  }

  &__actions {
    flex: 0 0 auto;
    margin-top: 1.5rem;

    @media (min-width: breakpoint(lg)) {
      margin-top: 0;
      margin-left: 40px;
    }
  }

  &__actions-row {
    margin: -4px;

    @media (min-width: breakpoint(md)) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__action {
    flex: 0 0 auto;
    max-width: 100%;
    padding: 4px;

    @media (min-width: breakpoint(md)) {
      width: auto;
    }
  }
}
</style>
