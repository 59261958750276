<template>
  <header
    class="rr-header"
    :class="{'rr-header_scrolled': isScrolled}"
  >
    <div class="rr-header__wrap">
      <div
        class="rr-header__container"
        :class="{'rr-header__container_md': !isWide}"
      >
        <!-- Mobile menu : BEGIN -->
        <div class="rr-header__col d-lg-none">
          <HeaderMenu
            class="rr-header__col-item"
            :live="live"
            :activeMenu="activeMenu"
            is-mobile
          ></HeaderMenu>
        </div>
        <!-- Mobile menu : END -->
        <div class="rr-header__col rr-header__col_center-mobile">
          <div class="rr-header__col-item">
            <a
              :href="$env.RussiaRunning"
              class="rr-header__logo"
            >
            <span class="rr-header-logo">
              <img src="/Content/images/logo-colorfull.svg" alt="" class="rr-header-logo__symbol">
            </span>
            </a>
          </div>
          <!-- Desktop menu : BEGIN -->
          <HeaderMenu
            class="rr-header__col-item rr-header__nav d-none d-lg-block"
            :activeMenu="activeMenu"
            :live="live"
          ></HeaderMenu>
          <!-- Desktop menu : END -->
        </div>
        <div class="rr-header__col rr-header__col_right">
          <!-- Lang switch : BEGIN -->
          <div class="rr-header__col-item d-none d-lg-block" v-if="activeLang">
            <Button
              ref="lang"
              variant="clear"
              shape="square"
            >
              <div class="flag-img">
                <img
                  :src="require(`./${activeLang.id}.jpg`)"
                  :alt="activeLang.id"
                >
              </div>
            </Button>
            <Dropdown
              trigger="lang"
              listeners="hover click"
              placement="bottom-end"
            >
              <template slot="items">
                <div
                  class="base-dropdown__item"
                  v-for="(item, i) in lang"
                  :key="i"
                  v-show="i !== currentLang"
                >
                  <a
                    class="base-dropdown__link"
                    href="#"
                    @click.prevent="changeLang(i)"
                  >
                    <span class="base-dropdown__icon flag-img">
                      <img
                        :src="require(`./${i}.jpg`)"
                        :alt="i"
                      >
                    </span>
                    <span class="base-dropdown__text">{{ item.name }}</span>
                  </a>
                </div>
              </template>
            </Dropdown>
          </div>
          <!-- Lang switch : END -->
          <!-- User / login  : BEGIN -->
          <div class="rr-header__col-item" v-if="!isAuthorized">
            <Button
              class="d-xl-none"
              variant="secondary"
              :href="`${$env.RussiaRunning}/login`"
              icon-left="user"
              shape="circle"
            ></Button>
            <Button
              class="d-none d-xl-flex"
              variant="secondary"
              :text="$t('header.my_account')"
              icon-left="user"
              :href="`${$env.RussiaRunning}/login`"
            >
            </Button>
          </div>
          <div class="rr-header__col-item rr-header__profile" v-else>
            <button class="rr-avatar" ref="avatar" type="button">
              <Avatar
                :username="user && user.Name"
                :img="user && user.ProfileImagePath"
              >
              </Avatar>
            </button>
            <Dropdown
              trigger="avatar"
              listeners="hover click"
              placement="bottom-end"
              strategy="fixed"
            >
              <template slot="items">
                <div
                  class="base-dropdown__item"
                  v-for="(profileItem, i) in profileNav"
                  v-show="!profileItem.hidden"
                  :key="i"
                >
                  <div class="divider" v-if="profileItem.isDivider"></div>
                  <a class="base-dropdown__link" :href="profileItem.link" v-else>
                    <Icon class="base-dropdown__icon" :name='profileItem.icon'></Icon>
                    <span class="base-dropdown__text">{{ profileItem.name }}</span>
                  </a>
                </div>
                <div class="divider"></div>
                <div class="base-dropdown__item">
                  <a :href="`${$env.RussiaRunning}/Auth/LogOff`" class="base-dropdown__link">
                    <Icon class="base-dropdown__icon" name="logout"></Icon>
                    <span class="base-dropdown__text">{{ $t('header.sign_out') }}</span>
                  </a>
                </div>
              </template>
            </Dropdown>
          </div>
          <!-- User / login  : END -->
        </div>
      </div>
    </div>
    <a
      v-show="showLiveTile"
      :href="liveTileUrl"
      class="live-tile-widget">
      live
    </a>
  </header>
</template>

<script>
import axios from 'axios';
import Avatar from '@/components/Avatar.vue';
import HeaderMenu from './HeaderMenu.vue';
import ru from './locales/ru.json';
import en from './locales/en.json';

export default {
  name: 'Header',
  i18n: {
    messages: {
      ru,
      en,
    },
  },
  props: {
    activeMenu: String,
    isWide: Boolean,
    hideLang: Boolean,
    title: String,
  },
  components: {
    HeaderMenu,
    Avatar,
  },
  data() {
    return {
      currentLang: 'ru',
      lang: {
        ru: { id: 'ru', name: 'Русский' },
        en: { id: 'en', name: 'English' },
      },
      profileNav: [
        {
          name: this.$t('header.profile'),
          icon: 'user',
          link: `${this.$env.RussiaRunning}/Account`,
        },
        {
          name: this.$t('header.documents'),
          icon: 'solution',
          link: `${this.$env.RussiaRunning}/MyAccount/admissions`,
        },
        {
          name: this.$t('header.registrations'),
          icon: 'badge',
          link: `${this.$env.RussiaRunning}/Account/RegistrationList`,
        },
        {
          name: this.$t('header.orders'),
          icon: 'shopping-bag',
          link: `${this.$env.RussiaRunning}/Account/OrderList`,
        },
        {
          name: this.$t('header.marks'),
          icon: 'five-of-five',
          link: `${this.$env.RussiaRunning}/Account/MyMarks`,
        },
        {
          name: this.$t('header.my_championships'),
          icon: 'trophy',
          link: `${this.$env.RussiaRunning}/Account/MyChampionships`,
        },
        {
          name: this.$t('header.teams'),
          icon: 'team',
          link: `${this.$env.RussiaRunning}/Account/MyTeams`,
        },
        {
          name: this.$t('header.my_clubs'),
          icon: 'user-groups',
          link: `${this.$env.RussiaRunning}/MyAccount/MyClubs`,
        },
        {
          name: this.$t('header.trainings'),
          icon: 'time-limit',
          link: `${this.$env.RussiaRunning}/MyAccount/Training`,
        },
        {
          name: this.$t('header.my_organisations'),
          icon: 'business',
          link: `${this.$env.RussiaRunning}/MyAccount/Organizations`,
          hidden: ['SSB', 'topliga2', 'BFLA', 'SteelCharacter', 'Samarkand', 'RZD', 'PionerCup', 'Legkovrace', 'Tula'].includes(this.theme),
        },
        {
          isDivider: true,
          hidden: !window.userInfo || (!window.userInfo.IsAdmin && !window.userInfo.IsBeneficiary),
        },
        {
          name: this.$t('header.organizers'),
          icon: 'caret-right',
          link: window.userInfo && window.userInfo.AdminUrl,
          hidden: !window.userInfo || (!window.userInfo.IsAdmin && !window.userInfo.IsBeneficiary),
        },
        {
          name: this.$t('header.bills'),
          icon: 'caret-right',
          link: `${this.$env.RussiaRunning}/Account/Bills`,
          hidden: !window.userInfo || !window.userInfo.IsAdmin,
        },
        {
          name: this.$t('header.cache'),
          icon: 'caret-right',
          link: `${this.$env.RussiaRunning}/CacheAdmin`,
          hidden: !window.userInfo || !window.userInfo.IsAdmin,
        },
      ],
      isScrolled: false,
      isAuthorized: true,
      user: null,
      live: false,
    };
  },
  computed: {
    activeLang() {
      return this.lang[this.currentLang] || {};
    },
    showLiveTile() {
      return this.live && this.$route.path === '/events';
    },
    liveTileUrl() {
      return this.$env.Live;
    },
  },
  methods: {
    toggleHeader() {
      this.isScrolled = window.pageYOffset > 10;
    },
    getLiveCount() {
      // const url = process.env.NODE_ENV === 'development' ? '' : this.$env.RussiaRunning;
      return axios('/api/events/live-count', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
        .then((response) => {
          if (response.data > 0) {
            this.live = true;
          }
        });
    },
    changeLang(lang) {
      const returnUrl = window.location.pathname;
      window.location.href = `/Culture/SetCulture?culture=${lang}&returnUrl=${returnUrl}`;
    },
  },
  mounted() {
    document.removeEventListener('scroll', this.toggleHeader);
    document.addEventListener('scroll', this.toggleHeader);
    this.getLiveCount();
  },
  created() {
    // eslint-disable-next-line no-underscore-dangle
    this.currentLang = window.__LANGUAGE__ || 'ru';
    this.user = window.userInfo;
    this.isAuthorized = !!this.user;
  },
};
</script>

<style lang="scss" scoped>
// live tile start
.live-tile-widget {
  display: none;
}
@media (max-width: 768px) {
  .live-tile-widget {
    display: block;
    position: fixed;
    bottom: 30px;
    left: -17px;
    text-decoration: none !important;
    transform: rotate(270deg);
    z-index: 1;
    color: #fff !important;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #ED1C24;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    padding: 11px 19px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    display: block;
  }
}
// live tile end

.rr-header {
  font-family: $font-family-base;
  background-color: $color-white;
  box-shadow: $elevation-deth-8;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  @include text-md;

  &__wrap {
    background-color: inherit;
    padding-left: 8px;
    padding-right: 8px;

    @media (min-width: breakpoint(md)) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__container, &__logo {
    height: 56px;

    @media (min-width: breakpoint(md)) {
      height: 64px;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &_md {
      max-width: 1224px;
    }
  }

  &__col {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    margin: 0 -10px;
    align-items: center;

    &_right {
      justify-content: flex-end;

      @media (min-width: breakpoint(lg)) {
        flex: 0 1 auto;
      }
    }

    &_center {
      justify-content: center;
      flex-basis: auto;
    }

    &_center-mobile {

      @media (max-width: breakpoint(lg, max)) {
        justify-content: center;
        flex-basis: auto;
      }
    }
  }

  &__col-item {
    flex: 0 0 auto;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 0;
  }

  &__col-divider {
    flex: 0 0 auto;
    width: 1px;
    height: 20px;
    background-color: $color-black-15;
    margin: 0 8px;

    @media (min-width: breakpoint(md)) {
      margin: 0 10px;
    }
  }

  &__logo {
    display: block;
    position: relative;
    width: 80px;

    &::before {
      content: "";
      position: absolute;
      display: block;
      background: $color-white;
      box-shadow: $elevation-deth-4;
      width: 100%;
      padding-top: 100%;
      border-radius: 50%;
      z-index: -1;
    }

    @media (min-width: breakpoint(md)) {
      width: 104px;
    }

    @media (min-width: breakpoint(lg)) {
      margin-left: -4px;
      margin-right: 8px;
    }

    @media (min-width: breakpoint(xl)) {
      margin-right: 32px;
    }

    .rr-header_external & {
      margin-right: -12px;
    }
  }

  &__profile {
    position: relative;
  }

  &__nav {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__title {
    color: $color-black-85;
  }

  &__to-site {
    @include text-sm;
    margin-right: 8px;

    .icon {

      @media (max-width: breakpoint(md, max)) {
        margin: 0;
      }
    }
  }

  &__to-site-text {

    @media (max-width: breakpoint(md, max)) {
      display: none;
    }
  }
}

.rr-header-logo {
  display: block;
  width: 100%;
  padding-top: 100%;
  position: relative;
  border-radius: 50%;

  &__symbol {
    display: block;
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.rr-header-logo, .rr-header__logo::before {
  transform-origin: top center;
  transition: $transition-default;
  transition-property: box-shadow, transform;

  .rr-header_scrolled & {
    transform: scale(0.7);

    @media (min-width: breakpoint(md)) {
      transform: scale(0.61);
    }
  }
}

.rr-avatar {
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  vertical-align: middle;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  user-select: none;
}

.flag-img {
  display: flex;
  align-items: center;
  width: 22px;

  img {
    flex: 0 0 auto;
    display: block;
    width: 100%;
    border: 1px solid #e8e6e8;
  }

  + .base-dropdown__text {
    padding-left: 32px;
  }
}

@keyframes showHeader {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>

<style lang="scss">
.rr-header-offset {
  flex: 0 0 auto;
  height: 56px;

  @media (min-width: breakpoint(md)) {
    height: 64px;
  }
}
</style>
