<template>
  <div class="rr-footer">
    <div class="rr-footer__container">
      <div class="rr-footer__top">
        <template
          v-for="(group, i) in nav"
        >
          <div
            class="rr-footer__nav rr-footer__top-item"
            :key="i"
          >
            <div class="rr-footer-group rr-footer-group_collapsed">
              <div class="rr-footer-group__title" @click="toggleFooterItem">
                {{ group.name }}
                <Icon class="rr-footer-group__title-icon" name="down"></Icon>
              </div>
              <div class="rr-footer-group__items">
                <div
                  class="rr-footer-group__item"
                  v-for="(item, j) in group.items"
                  :key="i + '_' + j"
                >
                  <RrLink
                    :href="item.href"
                    @click="item.action ? item.action($event) : null"
                    :text="item.name"
                    variant="inherit"
                    :target="item.target"
                  ></RrLink>
                </div>
              </div>
            </div>
          </div>
          <div class="rr-footer__divider d-md-none" :key="i + 'd'"></div>
        </template>
        <div class="rr-footer__divider rr-footer__divider_top d-lg-none"></div>
        <div class="rr-footer__top-item rr-footer__logos">
          <div class="rr-footer__logos-items">
            <a :href="$env.RussiaRunning" class="rr-footer__logos-item">
              <img src="./footer-logo.svg" alt="RR">
            </a>
          </div>
        </div>
        <div class="rr-footer__top-item rr-footer__apps">
          <div class="rr-footer-group p-0">
            <div class="rr-footer-group__title p-0">{{ $t('footer.get_app') }}</div>
            <div class="rr-footer-apps" style="padding: 8px 0;">
<!--              <a href="https://apps.apple.com/ru/app/russia-running-tracker/id1533225731" target="_blank" class="rr-footer-app">-->
<!--                <img src="./app-store-badge.svg" alt="">-->
<!--              </a>-->
              <a href="https://play.google.com/store/apps/details?id=com.mirbega.russiarunning" target="_blank" class="rr-footer-app">
                <img src="./google-play-badge.svg" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="rr-footer__divider"></div>
      <div class="rr-footer__links">
        <RrLink
          v-for="link in links"
          :key="link.name"
          :text="link.name"
          :href="link.href"
          class="rr-footer__links-item color-white"
          target="_blank"
          variant="inherit"
          size="sm"
        ></RrLink>
      </div>
      <div class="rr-footer__divider"></div>
      <div class="rr-footer__bottom">
        <div class="rr-footer__items">
          <div class="rr-footer__item">
            <div class="rr-footer__social">
              <a
                v-for="item in socials"
                :key="item.icon"
                class="rr-footer__social-item"
                :href="item.link"
                target="_blank"
              >
                <Icon :name="item.icon"></Icon>
              </a>
            </div>
          </div>
          <div class="rr-footer__item">
            <Button
              ref="langSwitch"
              variant="ghost"
              view="light"
              size="sm"
              :text="currentLang.text"
              icon-left="global"
            ></Button>
            <Dropdown
              trigger="langSwitch"
              listeners="hover click"
              placement="top-end"
              hide-overlay
              auto-width
            >
              <template slot="items">
                <div
                  class="base-dropdown__item"
                  :class="{'d-none': $lang === item.id}"
                  v-for="item in languages"
                  :key="item.id"
                >
                  <a
                    class="base-dropdown__link"
                    @click.prevent="changeLang(item.id)"
                  >
                    <span class="base-dropdown__icon flag-img">
                      <img :src="require(`./${item.id}.jpg`)" :alt="item.id">
                    </span>
                    <span class="base-dropdown__text">{{ item.text }}</span>
                  </a>
                </div>
              </template>
            </Dropdown>
          </div>
          <div
            v-html="`© ${year}, ${$t('footer.copyright')}`"
            class="rr-footer__item rr-footer__copyright">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import ru from './locales/ru.json';
import en from './locales/en.json';

export default {
  name: 'Footer',
  i18n: {
    messages: {
      ru,
      en,
    },
  },
  components: {
    Dropdown,
  },
  data() {
    return {
      nav: [
        {
          name: this.$t('footer.about_us'),
          items: [
            {
              name: this.$t('footer.platform'),
              href: `${this.$env.RussiaRunning}/Home/About`,
            },
            {
              name: this.$t('footer.events_rating'),
              href: `${this.$env.RussiaRunning}/Home/EventsRating`,
            },
            {
              name: this.$t('footer.journal'),
              href: 'http://life.russiarunning.com/',
              target: '_blank',
            },
            {
              name: this.$t('footer.advertising'),
              href: 'https://services.russiarunning.com/marketing-i-prodvizhenie',
              target: '_blank',
            },
            {
              name: this.$t('footer.careers'),
              href: `${this.$env.RussiaRunning}/Careers`,
            },
            {
              name: this.$t('footer.contacts'),
              href: `${this.$env.RussiaRunning}/Contacts`,
            },
          ],
        },
        {
          name: this.$t('footer.for_organizer'),
          items: [
            {
              name: this.$t('footer.create_event'),
              href: `${this.$env.RussiaRunning}/Home/CreateEventRequest`,
            },
            {
              name: this.$t('footer.features'),
              href: `${this.$env.RussiaRunning}/Home/About`,
            },
            {
              name: this.$t('footer.services'),
              href: 'https://services.russiarunning.com/?utm_source=rr&utm_medium=referral&utm_content=menu-footer',
              target: '_blank',
            },
          ],
        },
        {
          name: this.$t('footer.for_member'),
          items: [
            {
              name: this.$t('footer.find_event'),
              href: `${this.$env.RussiaRunning}/Events`,
            },
            {
              name: this.$t('footer.results'),
              href: this.$env.Results,
            },
            {
              name: this.$t('footer.championships'),
              href: `${this.$env.Championships}/Championships`,
            },
            {
              name: this.$t('footer.photo'),
              href: `${this.$env.Media}`,
            },
            {
              name: this.$t('footer.clubs'),
              href: `${this.$env.Clubs}`,
            },
          ],
        },
        {
          name: this.$t('footer.support'),
          items: [
            {
              name: this.$t('footer.help_center'),
              href: 'https://help.russiarunning.com',
            },
            {
              name: this.$t('footer.report_bug'),
              action: () => {
                if (window.Userback) {
                  window.Userback.open('bug');
                }
              },
            },
            {
              name: this.$t('footer.rate_website'),
              action: () => {
                if (window.Userback) {
                  window.Userback.open('general');
                }
              },
            },
          ],
        },
      ],
      socials: [
        // { icon: 'facebook', link: 'https://www.facebook.com/russiarunning/?fref=ts' },
        { icon: 'vkontakte', link: 'https://vk.com/russiarunning' },
        { icon: 'telegram', link: 'https://t.me/russiarunninglife' },
        // { icon: 'instagram', link: 'https://www.instagram.com/russiarunning/' },
        { icon: 'youtube', link: 'http://www.youtube.com/channel/UCjxF4IG3-uicefLABE5AV9A' },
      ],
      year: new Date().getFullYear(),
      languages: [
        { id: 'ru', text: 'Русский' },
        { id: 'en', text: 'English' },
      ],
      links: [
        {
          name: this.$t('footer.privacy_policy'),
          href: `${this.$env.RussiaRunning}/Content/files/Политика%20в%20отношении%20обработки%20персональных%20данных.pdf`,
        },
        {
          name: this.$t('footer.accreditation_it'),
          href: `${this.$env.RussiaRunning}/Content/files/08_АП-аккредит-я_ИТ-организация_№АО-20221205-10764551462-3_от_21.12.22.pdf`,
        },
        {
          name: this.$t('footer.it_activities'),
          href: `${this.$env.RussiaRunning}/Content/files/Информация%20о%20деятельности,%20осуществляемой%20Компанией%20Арена%20Плюс%20в%20области%20ИТ.pdf`,
        },
      ],
    };
  },
  computed: {
    currentLang() {
      return this.languages.find((o) => (o.id === this.$lang)) || {};
    },
  },
  methods: {
    toggleFooterItem(e) {
      e.target.parentNode.classList.toggle('rr-footer-group_collapsed');
    },
    changeLang(lang) {
      const returnUrl = window.location.pathname;
      window.location.href = `/Culture/SetCulture?culture=${lang}&returnUrl=${returnUrl}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Footer";
</style>
